
$spacing-small: 1rem;

.dataGrid {
  width: 100%;
  display: grid;
  grid-row-gap: 0.5rem;
  grid-template-columns: 8rem 1fr;

  .dataLabel {
    padding-right: 1rem;
    align-self: center;
    min-width: 8rem;

    label {
      margin-bottom: 0;
    }
  }

  .dataDescription {
    color: grey;
    margin-top: 0.75rem;
    margin-bottom: 0;
  }

  .dataValue {
    margin: 0;
    align-self: center;
  }
}
