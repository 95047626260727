body {
  background-color: #000011;
}

[contenteditable] {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}

.cesium-viewer .cesium-widget-credits {
  display: none;
}

:global {
  .emoji {
    font-family: "Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols;
    line-height: 1em;
    font-size: 1em;
  }

  .atlaskit-portal {
    & > div {
      bottom: 3.5rem;
      left: 4rem;

      & > div {
        width: 28.5rem !important;

        & > div {
          padding: 1.25rem;
          border-radius: 0.25rem;

          // Text
          & > div:first-child {
            height: 2.2rem;

            & > span:not(:first-child) {
              padding: 0 0 0 1.25rem;
            }

            & button > span {
              height: 2.2rem;
              width: 2.2rem;

              & > svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

code {
  display: inline-block;
  color: crimson;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(222,222,222,0.3);
  margin-top: 0.5rem;
}
