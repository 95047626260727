
.Group {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid grey;
}

.verticalAlign {
  display: flex;
  align-items: center;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}